import React from 'react';
import Section from "../section/Section";
import "./LawSuit.scss";
import VectorShops from './../../assets/images/vector-shops.png'
import VectorBuy from './../../assets/images/vector-buy.png'
import VectorPerson from './../../assets/images/vector-person.png'

const LawSuit = () => {
  return (
    <Section classes="LawSuit">
      <h2>Hoje, possuimos</h2>
      <div className="infos">
        <div className="info-full">
          <p>+1.218</p>
          <img className="vector-shops" src={VectorShops} alt="Imagem de um vector geométrico com números dentro"/>
          <p>Lojas ativas</p>
        </div>
        <div className="info-full">
          <p>+R$ 192.132</p>
          <img className="vector-buy" src={VectorBuy} alt="Imagem de um vector geométrico com números dentro"/>
          <p>processados em compras</p>
        </div>
        <div className="info-full">
          <p>+10.000</p>
          <img className="vector-person" src={VectorPerson} alt="Imagem de um vector geométrico com números dentro"/>
          <p>Pessoas compraram em nossas lojas</p>
        </div>
      </div>
    </Section>
  );
};

export default LawSuit;
