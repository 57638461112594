import React from 'react';
import './Pricing.scss'
import Section from "../section/Section";
import Button from "../button/Button";
import PricingImage from './../../assets/images/pricing.svg'

const Pricing = () => {
  return (
    <Section classes="Pricing">
      <div>
        <h2>Tudo de graça!</h2>
        <p>Está começando? Está sem dinheiro? Não tem problema.</p>
        <p>Para servidores que faturam R$500/mês nossos serviços são totalmente gratuitos</p>
        <p>Você terá a melhor loja sem pagar nada.</p>
        <Button variant="contained">Abrir minha loja</Button>
        <p>*Para demais casos, aplica-se 1,5% do faturamento mensal.</p>
      </div>
      <div>
        <img src={PricingImage} alt="Imagem de quadro de notas com moedas ao lado"/>
      </div>
    </Section>
  );
};

export default Pricing;
