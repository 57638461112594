import './App.scss';
import Navbar from "./navbar/Navbar";
import AboveTheFold from "./aboveTheFold/AboveTheFold";
import MainBenefits from "./mainBenefits/MainBenefits";
import Pricing from "./Pricing/Pricing";
import Partners from "./partners/Partners/Partners";
import LawSuit from "./LawSuit/LawSuit";
import OpenShop from "./OpenShop/OpenShop";
import Footer from "./Footer/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <AboveTheFold />
      <MainBenefits />
      <Pricing />
      <Partners />
      <LawSuit />
      <OpenShop />
      <Footer />
    </div>
  );
}

export default App;
