import React from 'react';
import Section from "../section/Section";
import './AboveTheFold.scss'
import Monitor from './../../assets/images/monitor.png'
import Button from "../button/Button";

const AboveTheFold = () => {
  return (
    <Section classes={"AboveTheFold"}>
      <div className={"image"}>
        <img src={Monitor} alt=""/>
      </div>
      <div className={"cta-container"}>
        <div className={"cta"}>
          <h1>Tenha a sua tão sonhada loja no ar em 30 segundos!</h1>
          O NGShop é simples, fácil e rápido de usar!
          Em poucos cliques tudo estará perfeito e pronto para a primeira venda de muitas.
          <Button variant={"contained"}>Criar minha loja gratuitamente</Button>
        </div>
      </div>
    </Section>
  );
};

export default AboveTheFold;
