import React from 'react';
import './Navbar.scss'
import logo from './../../assets/images/logo.png'
import Button from "../button/Button";

const Navbar = () => {
  return (
    <>
      <div className="Navbar">
        <img src={logo} alt=""/>
        <div className="actions">
          <Button size={"small"} variant="outlined">Já tenho conta</Button>
          <Button size={"small"} variant="contained">Criar minha loja</Button>
        </div>
      </div>
      <div className="navbar-spacer" />
    </>
  );
};

export default Navbar;
