import React from 'react';
import './Button.scss'

const Button = ({variant, size, children}) => {
  return (
    <button className={"Button " + variant + " " + size}>
      {children}
    </button>
  );
};

export default Button;
