import React from 'react';
import './Section.scss'

const Section = ({children, classes}) => {
  return (
    <div className={"Section " + classes}>
      {children}
    </div>
  );
};

export default Section;
