import React from 'react';
import Section from "../section/Section";
import Benefit from "../benefit/Benefit";
import './MainBenefits.scss'
import BenefitDomainIcon from './../../assets/images/benefit-domain.png'
import BenefitGraphsIcon from './../../assets/images/benefit-graphs.png'
import BenefitPluginsIcon from './../../assets/images/benefit-plugins.png'
import BenefitGatewaysIcon from './../../assets/images/benefit-gateways.png'

const MainBenefits = () => {
  return (
    <Section classes={"MainBenefits"}>
      <div className={"title"}>
        <h2>Criando sua loja no <strong>NGShop</strong> você terá</h2>
      </div>
      <div className={"benefits"}>
        <Benefit
          icon={BenefitDomainIcon}
          title={"Domínio gratuito"}
          description={"Ao criar sua loja na NGShop você recebe um domínio grátis!"}
        />
        <Benefit
          icon={BenefitGraphsIcon}
          title={"Ferramentas poderosas de monitoramento"}
          description={"Geramos métricas e gráficos sobre todas as partes da sua loja!"}
        />
        <Benefit
          icon={BenefitPluginsIcon}
          title={"Plugins nativos"}
          description={"Costumize e integre seu painel com diversos plugins nativos!"}
        />
        <Benefit
          icon={BenefitGatewaysIcon}
          title={"Principais gateways de pagamentos"}
          description={"Estamos sempre adicionando novos gateways de pagamento!"}
        />
      </div>
    </Section>
  );
};

export default MainBenefits;
