import React from 'react';
import Section from "../../section/Section";
import "./Partners.scss";
import PartnerBox from "../PartnerBox/PartnerBox";

const Partners = () => {
  let amountBox = [];

  for (let i = 0; i < 6; i++) {
    amountBox.push(i)
  }

  return (
    <Section classes="Partners">
      <div className="box-title">
        <p>Ainda não está convencido que somos sua melhor opção?</p>
        <p>Confira alguns de nossos parceiros oficiais</p>
      </div>
      <div className="partners-section">
        <div className="group-partners">
          {amountBox.map(() => {
            return <PartnerBox/>
          })}
        </div>
      </div>
    </Section>
  );
};

export default Partners;
