import React from 'react';
import Section from "../section/Section";
import "./OpenShop.scss";
import OpenShopImage from './../../assets/images/open-shop.png'
import ArrowDown from './../../assets/images/orange-down-arrow.png'
import Button from "../button/Button";

const OpenShop = () => {
  return (
    <Section classes="OpenShop">
      <p>Está esperando o que?</p>
      <p className="text-small">Abra sua loja hoje mesmo!</p>
      <div className="images-shop">
        <img src={OpenShopImage} alt="Imagem de quadro de notas com moedas ao lado"/>
      </div>
      <div className="box-button">
        <img src={ArrowDown} />
        <Button variant="contained">Abrir minha loja</Button>
      </div>
    </Section>
  );
};

export default OpenShop;
