import React from 'react';
import "./Footer.scss";
import Logo from './../../assets/images/logo.png';
import FooterImage from './../../assets/images/footer.png';

const Footer = () => {
  return (
    <div className="Footer">
      <div className="click-footer">
        <img src={Logo} />
        <div>
          <p>Contatos</p>
          <a target="_blank" href="https://discord.gg">Discord</a>
          <a target="_blank" href="https://discord.gg">Whatsapp</a>
        </div>
      </div>
      <div className="box-image" style={{ backgroundImage: `url(${FooterImage})`}}>
      </div>
    </div>
  );
};

export default Footer;
