import React from 'react';
import "./PartnerBox.scss"
import PartnerScreen from './../../../assets/images/partner-screen.png'

const PartnerBox = () => {
  return (
    <div className="PartnerBox">
      <img src={PartnerScreen} alt="Imagem parceiro do site"/>
    </div>
  );
};

export default PartnerBox;
