import React from 'react';
import './Benefit.scss'

const Benefit = ({icon, title, description}) => {
  return (
    <div className={"Benefit"}>
      <img src={icon} alt=""/>
      <div className={"description"}>
        <h3>{title}</h3>
        {description}
      </div>
    </div>
  );
};

export default Benefit;
